import { dateFormatLabels } from "~/src/modules/labels";
import dayjs from "dayjs";
import Joi from "joi";

import { loadLocationOptions } from "./helpers";

export const initialValues = {
  buildingPart: {
    id: "",
    name: "",
    sorting: ""
  },
  buyable: false,
  name: "",
  offerDataAvailable: false,
  rentable: false,
  saleDataAvailable: false,
  show: true,
  unit_category: {
    id: "",
    code: "",
    name: ""
  },
  verwertet: false,
  verwertetDate: new Date(),
  verwertetDateFormat: {
    label: dateFormatLabels.get("month"),
    value: "month"
  },

  hideTz: false,
  kvId: "",
  kvUrl: "",
  saleDate: "",
  salePriceGross: "",
  salePriceNet: "",
  tzNumber: "",
  tzYear: "",

  offerPriceBruttoInvestor: "",
  offerPriceBruttoInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceInvestor: "",
  offerPriceInvestorHistory: [
    {
      date: "",
      price: ""
    }
  ],
  offerPriceNormal: "",
  offerPriceNormalHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showOfferPriceBruttoInvestorHistory: false,
  showOfferPriceInvestorHistory: false,
  showOfferPriceNormalHistory: false,

  rentBk: "",
  rentBkHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentBrutto: "",
  rentBruttoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  rentNetto: "",
  rentNettoHistory: [
    {
      date: "",
      price: ""
    }
  ],
  showRentBkHistory: false,
  showRentBruttoHistory: false,
  showRentNettoHistory: false,

  privateBuyers: [],

  companyBuyers: [],

  floor: "",
  maisonette: false,
  position: "",
  positionGroup: "",
  positionStaircase: "",
  staircase: "",

  offerArea: "",
  saleArea: "",

  offerRoomCount: "",
  saleRoomCount: "",

  offerLoggiaArea: "",
  offerLoggiaCount: "",
  saleLoggiaArea: "",
  saleLoggiaCount: "",

  offerBalkonArea: "",
  offerBalkonCount: "",
  saleBalkonArea: "",
  saleBalkonCount: "",

  offerGartenArea: "",
  offerGartenCount: "",
  saleGartenArea: "",
  saleGartenCount: "",

  offerTerrasseArea: "",
  offerTerrasseCount: "",
  saleTerrasseArea: "",
  saleTerrasseCount: "",

  offerKellerArea: "",
  offerKellerCount: "",
  saleKellerArea: "",
  saleKellerCount: "",

  internalOfferNotes: "",
  internalSaleNotes: "",
  offerNotice: "",
  saleNotice: ""
};

/**
 *
 * @param unit
 * @example
 */
export async function generateEditValues(unit) {
  if (unit) {
    const { unit_category: uc } = unit;

    return {
      buildingPart: {
        id: unit.building_part?.id || "",
        label: unit.building_part?.name ? <p><span className="inline-block w-16 px-1 font-bold text-yellow-500">{Number.parseFloat(unit.building_part?.sorting)}</span>Bauteil: {unit.building_part.name}</p> : "",
        name: unit.building_part?.name || "",
        sorting: unit.building_part?.sorting || ""
      },
      buyable: unit.buyable || false,
      name: unit.name || "",
      offerDataAvailable: unit.offer_data_available || false,
      rentable: unit.rentable || false,
      saleDataAvailable: unit.sale_data_available || false,
      show: unit.show || false,
      unit_category: {
        id: uc.id || "",
        code: uc.code || "",
        label: uc.name || "",
        name: uc.name || ""
      },
      verwertet: unit.verwertet || false,
      verwertetDate: unit.verwertet_date ? new Date(unit.verwertet_date) : "",
      verwertetDateFormat: {
        label: unit.verwertet_date_format ? dateFormatLabels.get(unit.verwertet_date_format) : dateFormatLabels.get("day"),
        value: unit.verwertet_date_format || "day"
      },

      hideTz: unit.hide_tz,
      kvId: unit.kv_id || "",
      kvUrl: unit.kv_url || "",
      saleDate: unit.sale_date ? new Date(unit.sale_date) : "",
      salePriceGross: unit.sale_price_gross || "",
      salePriceNet: unit.sale_price_net || "",
      tzNumber: unit.tz_number || "",
      tzYear: unit.tz_year ? new Date(String(unit.tz_year)) : "",

      offerPriceBruttoInvestor: unit.offer_price_brutto_investor || "",
      offerPriceBruttoInvestorHistory: unit.offer_price_brutto_investor_history.length > 0
        ? unit.offer_price_brutto_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceInvestor: unit.offer_price_investor || "",
      offerPriceInvestorHistory: unit.offer_price_investor_history.length > 0
        ? unit.offer_price_investor_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      offerPriceNormal: unit.offer_price_normal || "",
      offerPriceNormalHistory: unit.offer_price_normal_history.length > 0
        ? unit.offer_price_normal_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showOfferPriceBruttoInvestorHistory: unit.show_offer_price_brutto_investor_history || false,
      showOfferPriceInvestorHistory: unit.show_offer_price_investor_history || false,
      showOfferPriceNormalHistory: unit.show_offer_price_normal_history || false,

      rentBk: unit.rent_bk || "",
      rentBkHistory: unit.rent_bk_history.length > 0
        ? unit.rent_bk_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentBrutto: unit.rent_brutto || "",
      rentBruttoHistory: unit.rent_brutto_history.length > 0
        ? unit.rent_brutto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      rentNetto: unit.rent_netto || "",
      rentNettoHistory: unit.rent_netto_history.length > 0
        ? unit.rent_netto_history
        : [
          {
            date: "",
            price: ""
          }
        ],
      showRentBkHistory: unit.show_rent_bk_history || false,
      showRentBruttoHistory: unit.show_rent_brutto_history || false,
      showRentNettoHistory: unit.show_rent_netto_history || false,

      privateBuyers: unit?.private_buyers?.length > 0
        ? await Promise.all(
          unit.private_buyers.map(async ({
            title_prefix: titlePrefix,
            title_suffix: titleSuffix,

            name,
            surname,

            birthdate,

            id,
            city,
            country,
            house_number: houseNumber,
            lat,
            lng,
            notes,
            show,
            street,
            zipcode
          }) => ({
            titlePrefix: titlePrefix || "",
            titleSuffix: titleSuffix || "",

            name: name || "",
            surname: surname || "",

            birthdate: birthdate ? new Date(birthdate) : null,

            id: id || null,
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            houseNumber: houseNumber || "",
            notes: notes || "",
            show: show ?? true,
            street: street || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      companyBuyers: unit?.company_buyers?.length > 0
        ? await Promise.all(
          unit.company_buyers.map(async ({
            id,
            city,
            country,
            house_number: houseNumber,
            is_zvr: isZvr,
            lat,
            lng,
            name,
            no_reg_number: noRegNumber,
            notes,
            reg_number: regNumber,
            show,
            street,
            zipcode
          }) => ({
            id: id || null,
            city: city || "",
            coordinates: lat && lng
              ? {
                label: (await loadLocationOptions(`${lng},${lat}`))?.[0]?.label,
                lat,
                lng
              }
              : null,
            country: country?.id
              ? {
                id: country.id,
                code: country.code,
                label: country.name,
                name: country.name
              }
              : null,
            houseNumber: houseNumber || "",
            isZvr: isZvr || false,
            name: name || "",
            noRegNumber: noRegNumber || false,
            notes: notes || "",
            regNumber: regNumber || "",
            show: show ?? true,
            street: street || "",
            zipcode: zipcode || ""
          }))
        )
        : [],

      floor: unit.floor || "",
      maisonette: unit.maisonette || false,
      position: unit.position ? Number.parseFloat(unit.position) : "",
      positionGroup: unit.position_group || "",
      positionStaircase: unit.position_staircase ? Number.parseFloat(unit.position_staircase) : "",
      staircase: unit.staircase || "",

      offerArea: unit.offer_area || "",
      saleArea: unit.sale_area || "",

      offerRoomCount: unit.offer_room_count || "",
      saleRoomCount: unit.sale_room_count || "",

      offerLoggiaArea: unit.offer_loggia_area || "",
      offerLoggiaCount: unit.offer_loggia_count || "",
      saleLoggiaArea: unit.sale_loggia_area || "",
      saleLoggiaCount: unit.sale_loggia_count || "",

      offerBalkonArea: unit.offer_balkon_area || "",
      offerBalkonCount: unit.offer_balkon_count || "",
      saleBalkonArea: unit.sale_balkon_area || "",
      saleBalkonCount: unit.sale_balkon_count || "",

      offerGartenArea: unit.offer_garten_area || "",
      offerGartenCount: unit.offer_garten_count || "",
      saleGartenArea: unit.sale_garten_area || "",
      saleGartenCount: unit.sale_garten_count || "",

      offerTerrasseArea: unit.offer_terrasse_area || "",
      offerTerrasseCount: unit.offer_terrasse_count || "",
      saleTerrasseArea: unit.sale_terrasse_area || "",
      saleTerrasseCount: unit.sale_terrasse_count || "",

      offerKellerArea: unit.offer_keller_area || "",
      offerKellerCount: unit.offer_keller_count || "",
      saleKellerArea: unit.sale_keller_area || "",
      saleKellerCount: unit.sale_keller_count || "",

      internalOfferNotes: unit.internal_offer_notes || "",
      internalSaleNotes: unit.internal_sale_notes || "",
      offerNotice: unit.offer_notice || "",
      saleNotice: unit.sale_notice || ""
    };
  }

  return initialValues;
}

const locationSchema = {
  city: Joi.string().empty("")
    .when("street", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),
  country: Joi.object({
    code: Joi.string().empty(""),
    name: Joi.string().empty(""),
    value: Joi.number().positive().empty("")
  }).unknown().rename("value", "id")
    .empty(null, "")
    .optional()
    .when("city", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("street", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.object().required()
    })
    .when("zipcode", {
      is: Joi.string().required(),
      then: Joi.object().required()
    }),
  houseNumber: Joi.string().empty(""),
  street: Joi.string().empty("")
    .when("houseNumber", {
      is: Joi.string().required(),
      then: Joi.string().required()
    }),
  zipcode: Joi.string().empty("")
};

const privateBuyerCheckSchema = Joi.custom((privateBuyer, helpers) => {
  const surnameIsEmpty = !privateBuyer.surname;

  const otherValuesAreSet = Object.values(privateBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

  if (privateBuyer && surnameIsEmpty && otherValuesAreSet) {
    return helpers.error("any.required");
  }

  return privateBuyer;
});

const privateBuyerSchema = Joi.object({
  titlePrefix: Joi.string().empty(""),
  titleSuffix: Joi.string().empty(""),

  name: Joi.string().empty(""),
  surname: Joi.string().empty(""),

  birthdate: Joi.date().empty(null).allow(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(privateBuyerCheckSchema, {
    otherwise: Joi.object({
      surname: Joi.required()
    })
  });

const companyBuyerCheckSchema = Joi.custom((companyBuyer, helpers) => {
  const nameIsEmpty = !companyBuyer.name;

  const otherValuesAreSet = Object.values(companyBuyer).some((value) => (typeof value === "string" && value !== "") || ((typeof value === "object" && value?.constructor === Object) && Object.keys(value).length > 0));

  if (companyBuyer && nameIsEmpty && otherValuesAreSet) {
    return helpers.error("any.required");
  }

  return companyBuyer;
});

const companyBuyerSchema = Joi.object({
  name: Joi.string().empty(""),
  regNumber: Joi.string().empty(""),

  coordinates: Joi.object({
    label: Joi.string(),
    lat: Joi.number(),
    lng: Joi.number()
  }).allow(null, ""),

  id: Joi.number().positive().empty("")
    .optional(),
  isZvr: Joi.boolean().default(false),
  noRegNumber: Joi.boolean().default(false),
  notes: Joi.string().empty(""),
  show: Joi.boolean().default(true)
})
  .append(locationSchema)
  .when(companyBuyerCheckSchema, {
    otherwise: Joi.object({
      name: Joi.required()
    })
  });

const schema = Joi.object({
  buildingPart: Joi.object({
    id: Joi.number().positive().empty(""),
    label: Joi.optional(),
    name: Joi.string().empty(""),
    sorting: Joi.number().positive().empty(""),
    value: Joi.optional()
  }).empty(null),
  buyable: Joi.boolean().required(),
  name: Joi.string().empty("").required(),
  offerDataAvailable: Joi.boolean().required(),
  rentable: Joi.boolean().required(),
  saleDataAvailable: Joi.boolean().required(),
  show: Joi.boolean().required(),
  unit_category: Joi.object({
    id: Joi.number().positive().empty("")
      .required(),
    code: Joi.string().empty("").required(),
    name: Joi.string().empty("")
  }).unknown(),
  verwertet: Joi.boolean().required(),
  verwertetDate: Joi.date()
    .empty(null).allow("")
    .optional()
    .when("saleDataAvailable", {
      is: true,
      then: Joi.when("offerDataAvailable", {
        is: true,
        then: Joi.when("verwertet", {
          is: true,
          then: Joi.date()
            .max(Joi.ref("saleDate", {
              adjust: (saleDate) => (saleDate
                ? dayjs(saleDate).subtract(1, "month").add(1, "day")
                  .toDate()
                : dayjs().add(1_000, "years").toDate())
            }))
            .error((errors) => {
              const error = errors.find(({ code }) => code === "date.max");

              error.code = "date.max.verwertet";

              return error;
            })
        })
      })
    }),
  verwertetDateFormat: Joi
    .when("verwertet", {
      is: true,
      otherwise: Joi.object().optional(),
      then: Joi.object({
        label: Joi.string().empty("").optional(),
        value: Joi.string().empty("").required()
      }).required()
    }),

  hideTz: Joi.boolean(),
  kvId: Joi.string().max(100).empty("")
    .optional(),
  kvUrl: Joi.string().uri().empty("")
    .optional(),
  tzNumber: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),
  tzYear: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty("").required()
    }),

  saleDate: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.date().empty("").optional(),
      then: Joi.date().empty(null).required()
    }),
  salePriceGross: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").optional()
    }),
  salePriceNet: Joi
    .when("saleDataAvailable", {
      is: true,
      otherwise: Joi.string().empty("").optional(),
      then: Joi.string().empty("").required()
    }),

  offerPriceBruttoInvestor: Joi.string().empty(""),
  offerPriceBruttoInvestorHistory: Joi.any(),
  offerPriceInvestor: Joi.string().empty(""),
  offerPriceInvestorHistory: Joi.any(),
  offerPriceNormal: Joi.string().empty(""),
  offerPriceNormalHistory: Joi.any(),
  showOfferPriceBruttoInvestorHistory: Joi.boolean(),
  showOfferPriceInvestorHistory: Joi.boolean(),
  showOfferPriceNormalHistory: Joi.boolean(),

  rentBk: Joi.string().empty(""),
  rentBkHistory: Joi.any(),
  rentBrutto: Joi.string().empty(""),
  rentBruttoHistory: Joi.any(),
  rentNetto: Joi.string().empty(""),
  rentNettoHistory: Joi.any(),
  showRentBkHistory: Joi.boolean(),
  showRentBruttoHistory: Joi.boolean(),
  showRentNettoHistory: Joi.boolean(),

  privateBuyers: Joi.array().items(privateBuyerSchema).sparse(),

  companyBuyers: Joi.array().items(companyBuyerSchema).sparse(),

  floor: Joi.string().empty(""),
  maisonette: Joi.boolean(),
  position: Joi.number().positive().precision(4)
    .empty(""),
  positionGroup: Joi.number().positive().precision(4)
    .empty(""),
  positionStaircase: Joi.number().positive().precision(4)
    .empty(""),
  staircase: Joi.string().empty("").pattern(/(^|\s)etage($|\s)/iu, { invert: true })
    .error((errors) => {
      const error = errors.find(({ code }) => code === "string.pattern.invert.base");

      error.code = "string.etage";

      return error;
    }),

  offerArea: Joi.string().empty(""),
  saleArea: Joi.string().empty(""),

  offerRoomCount: Joi.number().positive().empty(""),
  saleRoomCount: Joi.number().positive().empty(""),

  offerLoggiaArea: Joi.string().empty(""),
  offerLoggiaCount: Joi.number().positive().empty(""),
  saleLoggiaArea: Joi.string().empty(""),
  saleLoggiaCount: Joi.number().positive().empty(""),

  offerBalkonArea: Joi.string().empty(""),
  offerBalkonCount: Joi.number().positive().empty(""),
  saleBalkonArea: Joi.string().empty(""),
  saleBalkonCount: Joi.number().positive().empty(""),

  offerGartenArea: Joi.string().empty(""),
  offerGartenCount: Joi.number().positive().empty(""),
  saleGartenArea: Joi.string().empty(""),
  saleGartenCount: Joi.number().positive().empty(""),

  offerTerrasseArea: Joi.string().empty(""),
  offerTerrasseCount: Joi.number().positive().empty(""),
  saleTerrasseArea: Joi.string().empty(""),
  saleTerrasseCount: Joi.number().positive().empty(""),

  offerKellerArea: Joi.string().empty(""),
  offerKellerCount: Joi.number().positive().empty(""),
  saleKellerArea: Joi.string().empty(""),
  saleKellerCount: Joi.number().positive().empty(""),

  internalOfferNotes: Joi.string().empty(""),
  internalSaleNotes: Joi.string().empty(""),
  offerNotice: Joi.string().empty(""),
  saleNotice: Joi.string().empty("")
});

export default schema;
